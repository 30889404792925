<template>
  <div class="home">
    <TablePage ref="page" @approve="openDrawer" />
    <SubModal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      style="z-index: 2000"
      @onClose="closeModal"
    >
      <component
        :is="formName"
        ref="modalForm"
        @submit="onSubmit"
        @onClose="closeModal"
        :formConfig="formConfig"
        :defaultValue="defaultValue"
      />
    </SubModal>
  </div>
</template>

<script>
import TablePage from './table';
import SubModal from '../../../../../components/modal';
import { Approve } from '../components';

export default {
  name: 'page-rewrite',
  data() {
    return {
      modalConfig: {
        width: '600px',
        title: '提交审批',
        type: 'Full',
        visible: false,
      },
      formConfig: {},
      formName: 'Approve',
      defaultValue: {},
    };
  },
  components: {
    TablePage,
    SubModal,
    Approve,
  },
  mounted() {},
  methods: {
    // 保存提交审批
    openDrawer(params) {
      this.formConfig = params;
      this.modalConfig.visible = true;
    },
    // 关闭弹框
    closeModal() {
      const formData = this.$refs.modalForm.fApi.formData();

      if (formData) {
        this.defaultValue = formData;
      }

      this.modalConfig.visible = false;
    },
    // 保存
    onSubmit() {
      this.defaultValue = {};
      this.$refs.page.closeModal();
    },
  },
};
</script>

<style></style>
