import BasePage from '../../../../../../components/table_page';
import Form from '../form';
import RegisterDetail from '../../components/register';

import { ProcessForm } from '../../components';
import request from '../../../../../../utils/request';

export default {
  name: 'activity_manage',
  extends: BasePage,
  data() {
    return {
      configs: {
        formConfig: {
          item: [
            {
              field: 'fineName',
              search: 'true',
              title: '活动类型名称',
              type: 'select',
              apiUrl: '/tpm/tpmCostTypeFineController/list',
              optionsKey: {
                label: 'fineName',
                value: 'fineName',
              },
              isSearch: true,
              paramsName: 'fineName',
            },
          ],
        },
      },
    };
  },
  components: {
    Form,
    RegisterDetail,
    ProcessForm,
  },
  created() {
    this.getConfigList('tpm_activity_manage_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 待提交 create
      // 审批中 approving
      // 审批通过 approved
      // 驳回 rejected
      // 流程追回 interrupt
      // 活动关闭 closed
      const { approveStatus, processCode, isAllowRegistDetail } = row;
      if (
        code === 'edit'
        && approveStatus !== 'create'
        && approveStatus !== 'rejected'
        && approveStatus !== 'interrupt'
      ) {
        return false;
      }
      if (
        code === 'submit_approval'
        && isAllowRegistDetail === 'N'
        // && approveStatus !== 'create'
        // && approveStatus !== 'rejected'
      ) {
        return false;
      }
      if (code === 'register_detail' && approveStatus !== 'approved') {
        return false;
      }
      if (code === 'process_log' && !processCode) {
        return false;
      }
      return true;
    },
    // 按钮前置
    beforeButtonClick({ val }) {
      let list = []; let
        state = true;
      if (val.code === 'delete') {
        if (this.selectRow.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }

        list = this.selectRow.map((v) => v.approveStatus);

        state = list.every((v) => v === 'create');
        if (!state) {
          this.$message.error('只有待提交的数据允许删除操作');
          return false;
        }
      } else if (val.code === 'activities_close') {
        list = this.selectRow.map((v) => v.approveStatus);

        state = list.every((v) => v === 'approved');
        if (!state) {
          this.$message.error('只有审批通过的数据才能关闭');
          return false;
        }
      }
      return true;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'register_detail') {
        this.formConfig = {
          ...this.formConfig,
          code: 'add',
          row,
        };

        this.modalConfig.title = val.name || '查看';

        this.formName = 'RegisterDetail';
        this.openFull();
      } else if (code === 'submit_approval') {
        request
          .get('/tpm/tpmLiqueurActController/query', {
            id: row.id,
          })
          .then((res) => {
            if (res.success) {
              const rowData = { ...res.result };
              // 预算
              rowData.budgetControlVos = rowData.editBudgetVos
                ? rowData.editBudgetVos.map((item) => {
                  const v = item;
                  v.editId = v.id;
                  return v;
                })
                : [];

              // 组织
              rowData.orgCodeList = rowData.orgCode ? JSON.parse(rowData.orgCode) : [];
              // 客户
              rowData.customerCodeList = rowData.customerCode
                ? JSON.parse(rowData.customerCode)
                : [];
              rowData.saveType = '5';
              this.onSubmit({ row: rowData, submitUrl: '/tpm/tpmLiqueurActController/approve' });
            }
          });
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessForm';
        this.openDrawer();
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
